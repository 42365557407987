import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { DetailedCrewListTemplate, DetailedSchedule, ListedJob } from '@shared/types';
import {
  getSchedule,
  createSchedule,
  updateSchedule,
  getCrewListTemplates,
  getCrewListTemplate,
  createCrewListTemplate,
  updateCrewListTemplate,
  deleteCrewListTemplate,
  getSuperintendentEmployees,
  getScheduleJobs
} from './thunks';
import { ScheduleState } from './types';
import { RootState } from '@shared/store';
import { useSelector } from 'react-redux';
import { SuperintendentChoice } from '@components/pages/schedule-v2/types';

const initialState: ScheduleState = {
  currentSchedule: null,
  currentCrewListTemplate: null,
  currentDate: dayjs().format('YYYY-MM-DD'),
  activeSchedule: null,
  allCrewsPublished: false,
  crewListTemplates: [],
  isLoaded: false,
  isLoading: true,
  isSubmitting: false,
  errorMessages: [],
  crewListTemplateErrorMessages: [],
  isLoadingCrewListTemplates: false,
  hasLoadedSuperintendentEmployees: false,
  superintendentEmployees: [],
  hasLoadedJobs: false,
  isLoadingJobs: false,
  scheduleJobs: [],
  formIsValid: true,
  hasInitializedForm: false
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    startSubmitSchedule(state) {
      state.isSubmitting = true;
    },
    endSubmitSchedule(state) {
      state.isSubmitting = false;
    },
    startLoadSchedule(state) {
      state.isLoading = true;
    },
    endLoadSchedule(state) {
      state.isLoading = false;
    },
    setScheduleError(state, action: PayloadAction<string[]>) {
      state.errorMessages = action.payload;
    },
    changeCurrentDate(state, action: PayloadAction<string>) {
      state.currentDate = action.payload;
      // reset all schedule state
      state.currentSchedule = null;
      state.activeSchedule = null;
      state.currentCrewListTemplate = null;
    },
    setScheduleCurrentDate(state, action: PayloadAction<string>) {
      state.currentDate = action.payload;
    },
    setActiveSchedule(state, action: PayloadAction<DetailedSchedule | null>) {
      state.activeSchedule = action.payload;
    },
    setCurrentCrewListTemplate(state, action: PayloadAction<DetailedCrewListTemplate | null>) {
      state.currentCrewListTemplate = action.payload;
    },
    setSuperintendentEmployees(state, action: PayloadAction<SuperintendentChoice[]>) {
      state.superintendentEmployees = action.payload;
      if (action.payload.length === 0) {
        state.hasLoadedSuperintendentEmployees = false;
      }
    },
    updateScheduleJobs(state, action: PayloadAction<ListedJob[]>) {
      state.scheduleJobs = action.payload;
    },
    setFormIsValid(state, action: PayloadAction<boolean>) {
      state.formIsValid = action.payload;
    },
    setHasInitializedForm(state, action: PayloadAction<boolean>) {
      state.hasInitializedForm = action.payload;
    },
    setCrewListTemplates(state, action: PayloadAction<DetailedCrewListTemplate[]>) {
      state.crewListTemplates = action.payload;
    }
  },
  extraReducers: builder => {
    /** GET SCHEDULE **/

    builder.addCase(getSchedule.pending, state => {
      state.isLoading = true;
      state.isLoaded = false;
    });

    builder.addCase(getSchedule.fulfilled, (state, action) => {
      state.currentSchedule =
        action.payload == null
          ? null
          : {
              ...action.payload,
              crews: action.payload.crews.sort((a, b) => (a.ordinal < b.ordinal ? -1 : 1))
            };
      state.isLoading = false;
      state.isLoaded = true;
      state.errorMessages = [];
    });

    builder.addCase(getSchedule.rejected, state => {
      state.currentSchedule = null;
      state.isLoading = false;
      state.isLoaded = true;
    });

    /** CREATE SCHEDULE **/

    builder.addCase(createSchedule.pending, state => {
      state.errorMessages = [];
      state.isSubmitting = true;
    });

    builder.addCase(createSchedule.fulfilled, (state, action) => {
      state.currentSchedule = action.payload;
      state.isSubmitting = false;
    });

    builder.addCase(createSchedule.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }
      state.isSubmitting = false;
    });

    /** UPDATE SCHEDULE **/

    builder.addCase(updateSchedule.pending, state => {
      state.errorMessages = [];
      state.isSubmitting = true;
    });

    builder.addCase(updateSchedule.fulfilled, (state, action) => {
      state.currentSchedule = {
        ...action.payload,
        crews: action.payload.crews.sort((a, b) => (a.ordinal < b.ordinal ? -1 : 1))
      };
      state.isSubmitting = false;
    });

    builder.addCase(updateSchedule.rejected, (state, action) => {
      if (action.error.message) {
        state.errorMessages = action.error.message.split('|');
      }
      state.isSubmitting = false;
    });

    /** GET CREW LIST TEMPLATES **/

    builder.addCase(getCrewListTemplates.pending, state => {
      state.isLoadingCrewListTemplates = true;
    });

    builder.addCase(getCrewListTemplates.fulfilled, (state, action) => {
      state.crewListTemplates = action.payload.data;
    });

    /** GET CREW LIST TEMPLATE **/

    builder.addCase(getCrewListTemplate.fulfilled, (state, action) => {
      state.currentCrewListTemplate = action.payload;
    });

    /** CREATE CREW LIST TEMPLATE **/

    builder.addCase(createCrewListTemplate.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(createCrewListTemplate.fulfilled, (state, action) => {
      state.currentCrewListTemplate = action.payload;
      state.crewListTemplates.push(action.payload);
      state.isSubmitting = false;
    });

    builder.addCase(createCrewListTemplate.rejected, (state, action) => {
      if (action.error.message) {
        state.crewListTemplateErrorMessages = action.error.message.split('|');
      }
      state.isSubmitting = false;
    });

    /** UPDATE CREW LIST TEMPLATE **/

    builder.addCase(updateCrewListTemplate.pending, state => {
      state.isSubmitting = true;
    });

    builder.addCase(updateCrewListTemplate.fulfilled, (state, action) => {
      state.currentCrewListTemplate = action.payload;
      state.isSubmitting = false;
    });

    /** DELETE CREW LIST TEMPLATE **/

    builder.addCase(deleteCrewListTemplate.fulfilled, (state, action) => {
      state.crewListTemplates = state.crewListTemplates.filter(
        template => template.id !== action.meta.arg
      );
      state.isSubmitting = false;
    });

    /** GET SUPERINTENDENT EMPLOYEES **/

    builder.addCase(getSuperintendentEmployees.fulfilled, (state, action) => {
      state.superintendentEmployees = action.payload.data;
      state.hasLoadedSuperintendentEmployees = true;
    });

    /** GET SCHEDULE JOBS **/
    builder.addCase(getScheduleJobs.pending, state => {
      state.isLoadingJobs = true;
    });
    builder.addCase(getScheduleJobs.fulfilled, (state, action) => {
      state.scheduleJobs = action.payload.data;
      state.isLoadingJobs = false;
      state.hasLoadedJobs = true;
    });
    builder.addCase(getScheduleJobs.rejected, (state, action) => {
      state.errorMessages = action.error.message?.split('|') ?? [];
      state.isLoadingJobs = false;
    });
  }
});

export const {
  startSubmitSchedule,
  endSubmitSchedule,
  startLoadSchedule,
  changeCurrentDate,
  endLoadSchedule,
  setScheduleError,
  setScheduleCurrentDate,
  setActiveSchedule,
  setCurrentCrewListTemplate,
  setSuperintendentEmployees,
  updateScheduleJobs,
  setFormIsValid,
  setHasInitializedForm,
  setCrewListTemplates
} = scheduleSlice.actions;
export default scheduleSlice.reducer;

export const useScheduleSelector = (): ScheduleState =>
  useSelector<RootState, ScheduleState>(state => state.schedule);
